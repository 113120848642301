const Utils = require('../utils');
const AdserverBase = require('../adserverBase');
const DivSlot = require('../divSlot');
const { ADS_OPTI_FLOOR } = require('../sharedConstants');

const gamSlotOf = (slot) => slot?.getGamSlot?.() || slot;

class GamDelayedSlot extends DivSlot {
	getGamSlot() {
		return Utils.find(window.googletag?.pubads?.()?.getSlots?.() || [], (gamSlot) => (
			gamSlot.getSlotElementId() === this.getSlotElementId()
		));
	}

	waitGamSlot(cb) {
		Utils.withGoogle(() => {
			cb(this.getGamSlot());
		});
	}

	setTargeting(key, val) {
		this.setTempTargeting(key, val);
		this.waitGamSlot((slot) => slot && this.adserver.gamCall(slot, 'setTargeting', key, val));
	}

	updateTargetingFromMap(kvMap) {
		Utils.entries(kvMap).forEach(([k, v]) => this.setTargeting(k, v));
	}

	setTempTargeting(key, val) {
		this.tmpTarg = this.tmpTarg || {};
		this.tmpTarg[key] = typeof val === 'string' ? [val] : val;
	}

	getTargeting(key) {
		const slot = this.getGamSlot();
		return slot?.getTargeting(key) || this.tmpTarg?.[key];
	}
}

class GamAdserver extends AdserverBase {
	init(__, doneCb) {
		const { noGpt } = this.auction;
		if (!noGpt && !GamAdserver.gptLoaded) {
			GamAdserver.gptLoaded = true;
			Utils.loadScript('//securepubads.g.doubleclick.net/tag/js/gpt.js');
		}
		this.maybeWaitGoogle(doneCb);
	}

	getEventIfs() {
		const fn = (op, type, listener) => this.maybeWaitGoogle(() => {
			this.gamCall(this.googletag.pubads(), op, type, listener);
		}, true);
		return {
			transform: (ev) => ({ ...ev, ...this.getSlotResponseInfo(ev) }),
			add: (type, listener) => fn('addEventListener', type, listener),
			remove: (type, listener) => fn('removeEventListener', type, listener),
		};
	}

	doFirstTimeInit() {
		this.maybeWaitGoogle(() => {
			this.gamCall(this.googletag.pubads(), 'addEventListener', 'slotRenderEnded', ({ slot }) => {
				this.auction.pbRequester.registerRenderedDivId(
					slot.getSlotElementId(),
					slot.getTargeting('hb_adid')?.[0] || null,
				);
			});
		}, true);
	}

	maybeWaitGoogle(cb, forceWait) {
		if (this.auction.delayedAdserverLoading && !forceWait) {
			cb();
		} else {
			Utils.withGoogle((googletag) => {
				this.googletag = googletag;
				cb();
			});
		}
	}

	static destroySlots() {
		window.googletag?.destroySlots?.();
	}

	adUnitInit({ adUnit }) {
		adUnit.setAdUnitPaths = function (paths) {
			this.gamPath = paths.join('\n');
		};
	}

	gamCall(obj, fnName, ...args) {
		const fn = this.auction.googletagCalls?.[fnName];
		// For special-cases we might get GamDelayedSlot instances, we don't want to provide that to the callback fn
		return fn && !(obj instanceof GamDelayedSlot)
			? fn.call({ obj, auction: this.auction }, ...args)
			: obj[fnName](...args);
	}

	getSlotResponseInfo({ slot }) {
		const info = gamSlotOf(slot)?.getResponseInformation();
		return !info ? null : {
			advertiserId: info.advertiserId,
			orderId: info.campaignId,
			lineItemId: info.sourceAgnosticLineItemId || info.lineItemId,
		};
	}

	normalizeAdUnitPath(path, suppliedByPage /** if true, doesn't include possible device-prefix */) {
		const res = Utils.normalize(path);
		const devicePfx = Utils.getGamDevicePrefix(this);
		if (suppliedByPage && devicePfx) {
			if (this.customDimStr) {
				const parts = res.split('/');
				if (parts.length >= 2) {
					parts[parts.length - 1] = `${devicePfx}_${parts[parts.length - 1]}`;
					return parts.join('/');
				}
			} else {
				return `${res}/${devicePfx}`;
			}
		}
		return res;
	}

	adUnitPathsFromUnitInternal(unit) {
		return Utils.normalizedPaths(unit.gamPath, true);
	}

	updateAdUnitPath(dstAdUnit, adUnitPath) {
		dstAdUnit.gamPath = adUnitPath;
		return true;
	}

	getCodeStart(path) {
		return `/${path}`;
	}

	getType() {
		return 'google';
	}

	getAdserverProps() {
		return Utils.assign(super.getAdserverProps(), {
			delayedSendAdserver: true,
		});
	}

	getSlots() {
		if (this.auction.delayedAdserverLoading) {
			return GamDelayedSlot.list();
		}
		if (!this.googletag?.pubads) {
			return [];
		}
		return this.gamCall(this.googletag.pubads(), 'getSlots');
	}

	getAmazonIntegrationInfo() {
		return {
			adServerName: 'googletag', // config.adServer
			useSetDisplayBids: true,
		};
	}

	rlvConvertedAdUnitPath(slot) {
		return Utils.rlvConvertedGamAdUnitPath(slot, this);
	}

	createSlot({
		path, sizes, divId, placementType,
	}) {
		const { customDimStr, auction } = this;
		const { pbRequester } = auction;
		let finalPath = Utils.injectMcmPart(path, pbRequester.mcmChildNwid);
		let customDimVal;
		if (customDimStr) {
			const idx = finalPath.lastIndexOf('/');
			if (idx > 0) {
				customDimVal = finalPath.substring(idx + 1);
				finalPath = finalPath.substring(0, idx);
			}
		}
		const szs = placementType.makeFluid ? [...sizes, 'fluid'] : sizes;
		const createGamSlot = () => {
			const gamSlot = this.gamCall(this.googletag, 'defineSlot', finalPath, szs, divId);
			if (gamSlot) {
				gamSlot.addService(this.googletag.pubads());
			}
			return gamSlot;
		};
		let slot;
		if (auction.delayedAdserverLoading) {
			slot = GamDelayedSlot.getOrCreateSlot(divId, finalPath, { adserver: this });
			this.maybeWaitGoogle(createGamSlot, true);
			if (customDimVal) {
				slot.setTargeting(customDimStr, customDimVal);
			}
		} else {
			slot = createGamSlot();
			if (customDimVal) {
				this.gamCall(slot, 'setTargeting', customDimStr, customDimVal);
			}
		}
		return slot;
	}

	oneTimePageSetup() {
		this.maybeWaitGoogle(() => {
			const { googletag } = this;
			this.gamCall(googletag.pubads(), 'disableInitialLoad');
			this.gamCall(googletag.pubads(), 'enableSingleRequest');
			this.gamCall(googletag, 'enableServices');
		}, true);
	}

	sendAdserverRequest(params) {
		this.maybeWaitGoogle(() => {
			this.sendAdserverRequestInternal(params);
			params.onRequestSent();
		}, true);
	}

	setupLazyLoading() {
		const { googletag } = this;
		const { enabled, ...settings } = this.auction.data.rlvGamLazyLoad;

		if (!enabled) {
			return;
		}

		Utils.entries(settings).forEach(([key, value]) => {
			if (value == null) {
				delete settings[key];
			}
		});
		this.gamCall(googletag.pubads(), 'enableLazyLoad', settings);
	}

	getFloorTargeting(floor) {
		const { buckets, targKey } = this.floorInfo || {};
		if (typeof floor !== 'number' || !buckets?.length) {
			return null;
		}
		let best = -1;
		if (floor === ADS_OPTI_FLOOR) {
			best = buckets.indexOf(ADS_OPTI_FLOOR);
		} else {
			buckets.forEach((val, idx) => {
				// When there is no exact match, take the highest value *below* floor
				if (val !== null && val <= floor && (best < 0 || val > buckets[best])) {
					best = idx;
				}
			});
		}
		return best < 0 ? null : {
			[targKey]: best.toString(),
			rlv_floor_set: 'on',
		};
	}

	/** Return possible mapping from HBA custom dimensions and GAM key-values */
	getGranularTarg(includeClear) {
		const { custKvMap, auction } = this;
		if (!custKvMap) {
			return null;
		}
		const params = auction.hbaAuction?.getCustomParams();
		const res = {};
		custKvMap.forEach(([key, dims]) => {
			const parts = [];
			dims.forEach(([name, id, vals]) => {
				const curr = params?.[name];
				const valId = vals[curr];
				if (valId) {
					parts.push(`${id}_${valId}`);
				}
			});
			if (parts.length) {
				res[key] = parts.join('_');
			} else if (includeClear) {
				res[key] = null;
			}
		});
		return res;
	}

	getGlobalTargeting(includeClear) {
		return {
			...super.getGlobalTargeting(includeClear),
			...this.getGranularTarg(includeClear),
		};
	}

	sendAdserverRequestInternal({ requestAuction, unknownSlotsToLoad, usedUnitDatas, isHbLess }) {
		const {
			pbjs, isReloadAuction,
			pbRequester, noAdsInitRequestAll,
			allowedDivIds, alwDivImplyNoReqAll,
		} = requestAuction;
		const { googletag } = this;

		this.setupLazyLoading();
		const codes = usedUnitDatas.map((u) => u.code);
		if (pbjs.setTargetingForGPTAsync && codes.length) { // We're not 100% sure as pbjs-loading might have failed
			requestAuction.pbjsCall('setTargetingForGPTAsync', codes, (slot) => (adUnitCode) => {
				const id = AdserverBase.codeToId[adUnitCode];
				if (id) {
					return id === slot.getSlotElementId();
				}
				return Utils.cleanMcmPart(slot.getAdUnitPath()) === Utils.cleanMcmPart(adUnitCode)
					|| slot.getSlotElementId() === adUnitCode;
			});
		}
		usedUnitDatas.forEach(({ adUnit, slot }) => {
			const gamSlot = gamSlotOf(slot);
			const floorTarg = this.getFloorTargeting(adUnit.adsFloor);
			if (floorTarg) { // set floor targeting
				this.gamCall(gamSlot, 'updateTargetingFromMap', floorTarg);
			}
			// Force banner adserver targeting if option is set
			if (adUnit.videoSettings?.adserverTargetingOptions?.forceBannerAdserverTargeting) {
				const targeting = gamSlot.getTargeting('hb_format');
				if (targeting.indexOf('video') !== -1 && targeting.indexOf('banner') === -1) {
					this.gamCall(gamSlot, 'setTargeting', 'hb_format', 'banner');
				}
			}
		});
		let refreshSlots;
		const allSlots = this.getSlots();

		const globalTargeting = this.getGlobalTargeting(true);
		Utils.entries(globalTargeting).forEach(([k, v]) => {
			const params = v === null ? ['clearTargeting', k] : ['setTargeting', k, v];
			this.gamCall(googletag.pubads(), ...params);
		});

		const perSlot = GamAdserver.firstRequestDone || noAdsInitRequestAll || isHbLess
			|| (allowedDivIds && alwDivImplyNoReqAll)
			// When having multiple GAM adservers we don't want to call refresh() without arguments as that
			// would load placements for the other adserver(s)
			|| requestAuction.getNonInstreamOnlyAdservers().filter((a) => a.type === this.type).length >= 2;
		if (perSlot) {
			refreshSlots = usedUnitDatas.map(({ slot }) => {
				if (isReloadAuction) {
					const gamSlot = gamSlotOf(slot);
					if (gamSlot?.getResponseInformation()) {
						gamSlot.setCollapseEmptyDiv(false, false); // don't collapse when reloading and there was an ad
					}
				}
				return slot;
			});
			refreshSlots.push(...unknownSlotsToLoad);
		} else {
			refreshSlots = allSlots.filter((slot) => (
				(!slot.creatorPbAuction || slot.creatorPbAuction === requestAuction)
				&& !pbRequester.lazyLoader?.isLazyLoadingSlot(requestAuction, slot)
			));
			if (allSlots.length === refreshSlots.length) {
				refreshSlots = null;
			}
		}
		GamAdserver.firstRequestDone = true;
		if (refreshSlots) {
			const arr = refreshSlots.filter((slot) => !slot.noRefresh);
			const seenSlots = new Set(); // To support "shared" slots
			const gamSlotArr = arr.map(gamSlotOf).filter((s) => {
				if (!s || seenSlots.has(s)) {
					return false;
				}
				seenSlots.add(s);
				return true;
			});
			if (gamSlotArr.length) {
				this.gamCall(googletag.pubads(), 'refresh', gamSlotArr);
			}
		} else if (allSlots.length) {
			this.gamCall(googletag.pubads(), 'refresh');
		}
	}
}

module.exports = GamAdserver;
